<template>
  <div class="container mt-5">
    <div v-if="hasAccess">
      <slot></slot>
    </div>
    <div v-else class="card shadow-sm">
      <div class="card-body text-center p-5">
        <i class="bi bi-shield-lock-fill text-primary display-1 mb-4"></i>
        <h2 class="card-title mb-4">Access Required</h2>
        <p class="card-text text-muted mb-4">
          To view this page, you must be logged in and have a linked ORCID account.
        </p>
        <div class="d-grid gap-2 col-md-6 mx-auto">
          <button 
            v-if="!isLoggedIn" 
            @click="redirectToLogin" 
            class="btn btn-primary btn-lg"
          >
            <i class="bi bi-box-arrow-in-right me-2"></i>Log In
          </button>
          <button 
            v-if="isLoggedIn && !hasOrcidConnected" 
            @click="connectOrcid" 
            class="btn btn-outline-secondary btn-lg"
          >
            <i class="bi bi-link-45deg me-2"></i>Connect ORCID
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AccessControl',
  computed: {
    ...mapGetters(['userStatus']),
    isLoggedIn() {
      return this.userStatus.isLoggedIn;
    },
    hasOrcidConnected() {
      return !!this.userStatus.orcidId;
    },
    hasSubscription() {
      return this.userStatus.subscriptionStatus === 'active'; // Adjust based on your actual subscription status values
    },
    hasAccess() {
      return (this.isLoggedIn && this.hasOrcidConnected) || this.hasSubscription;
    }
  },
  methods: {
    redirectToLogin() {
      // Implement your login redirect logic here
      this.$router.push('/login');
    },
    connectOrcid() {
      // Implement your ORCID connection logic here
      this.$router.push('/linktoorcid');
    }
  }
}
</script>